import '../css/main.css';

import 'vite/modulepreload-polyfill';
import htmx from 'htmx.org';
import Alpine from 'alpinejs';
import './lib/collect';

// alpine plugins
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';

// htmx extensions
import './extensions/ajaxHeader';
import './extensions/anyAutoComplete';
import './extensions/hourSlider';
import './extensions/packageSelector';
import './extensions/paymentPlans';
import './extensions/roleCopy';
import './extensions/searchAutoComplete';
import './extensions/styleSelector';
import './extensions/treatmentSelector';
import './extensions/validateForm';
import './extensions/vendorAutoComplete';

// misc
import './magic-grid';

// globally available shoelace components
// per page shoelace components should NOT be added here, but instead should use the `shoelace` element:
// <?= $this->element('layout/shoelace', ['components' => ['tooltip']]) ?>

import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
setBasePath('/newtrue/shoelace/cdn');

// @ts-expect-error htmx is a global variable
window.htmx = htmx;

// @ts-expect-error Alpine is a global variable
window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.start();
